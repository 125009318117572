<template>
    <div class="container-information-O">
        <div class="page-information-O">
            <div class="header-box-2">
                <div class="page-information-O-header">
                    <p>OKULISTA</p>
                </div>
                <div class="page-information-O-border"></div>
            </div>
            <div class="label-box-2">
                <div class="page-information-O-label">
                    <div class="page-information-O-label-content">
                        <div class="page-information-O-label-info">
                            <div class="info-2"><span>Dr n.med. Małgorzata Kowalczyk</span></div>
                            <p><span>Specjalista Chorób Oczu</span></p>
                            <p>Starszy asystent w Katedrze i Klinice Okulistyki<br>
                            Ogólnej i Dziecięcej w Lublinie</p>
                        </div>
                        <div class="page-information-O-label-border-between"></div>
                        <div class="page-information-O-label-info-2">
                            <div class="page-information-O-label-info">
                                <div class="phone-2"><span><font-awesome-icon icon="phone" pull="left" :transform="{ rotate: 100 }"/></span>REJESTRACJA</div>
                                <a class="telefon" href="tel: +48604540510"><p>tel. 604 540 510</p></a>
                            </div>
                            <div class="page-information-O-label-border-between-2"></div>
                            <div class="page-information-O-label-info">
                                <div class="timetable"><span><font-awesome-icon :icon="['far', 'clock']" /></span>GODZINY PRACY</div>
                                <p>Wt: 14:00-16:00</p>
                                <p>Czw: 8:00-12:00</p>
                                <p>Pt: 15:00-19:00</p>
                                <p>Pozostałe dni,<br>w tym soboty<br>po telefonicznym<br>uzgodnieniu wizyty</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-information-O-box">
                <div class="page-information-O-info">
                    <h2>CZŁONKOSTWO</h2>
                    <ul>
                        <li><span>Polskie Towarzystwo Okulistyczne</span></li>
                        <li><span>Sekcja Dziecięca Polskiego Towarzystwa Okulistycznego</span></li>
                        <li><span>Sekcja Strabologiczna Polskiego Towarzystwa Okulistycznego</span></li>
                        <li><span>World Society of Paediatric Ophthalmology and Strabismus</span></li>
                    </ul>
                    <h2>ZAKRES DZIAŁALNOŚCI</h2>
                    <ul>
                        <li>Diagnostyka i leczenie chorób narządu wzroku wieku dziecięcego z uwzględnieniem chorób rzadkich.</li>
                        <li>Diagnostyka i leczenie niedrożności dróg łzowych u dzieci.</li>
                        <li>Wykrywanie i leczenie wad wzroku u dzieci.
                            <ul>
                                <li>Leczenie krótkowzroczności za pomocą 0.01% Atropiny.</li>
                            </ul>
                        </li>
                        <li>Wykrywanie i leczenie zaburzeń akomodacji i konwergencji.
                            <ul>
                                <li>Diagnostyka i leczenie skurczu akomodacji powodującego pseudokrótkowzroczność.</li>
                            </ul>
                            <ul>
                                <li>Obiektywne i subiektywne badanie zaburzeń akomodacji, konwergencji oraz określanie oka dominującego za pomocą linijki RAF.</li>
                            </ul>
                        </li>
                        <li>Diagnostyka i leczenie zeza u dzieci i dorosłych.
                            <ul>
                                <li>Badanie ostrości wzroku, pomiar kąta zeza listwami pryzmatycznymi, badanie obuocznego widzenia, dobór okularów.</li>
                            </ul>
                        </li>
                        <li>Leczenie niedowidzenia u dzieci.
                            <ul>
                                <li>Badanie ostrości wzroku za pomocą tablic logarytmicznych do dali i bliży, ocena stopnia niedowidzenia przy użyciu tablic wykorzystujących zjawisko natłoku, ocena ostrości wzroku za pomocą tablic Lea Hyvärinen do dali i bliży. Leczenie niedowidzenia na podstawie zaleceń amerykańskiej grupy PEDIG (Pediatric Eye Disease Investigator Group).</li>
                            </ul>
                        </li>
                        <li>Ocena stopnia obuocznego widzenia.
                            <ul>
                                <li>Stosowane są różne testy określające obecność lub brak oraz zakres jednoczesnej percepcji i fuzji. Do badania jednoczesnej percepcji wykorzystywany jest test Wortha do dali i bliży. Fuzja badana jest za pomocą listwy pryzmatycznej. Obecność stereoskopii czyli widzenia przestrzennego (3D), które jest najwyższym stopniem widzenia obuocznego jest wykrywana za pomocą testu Motyla, testu TNO i testu Langa.</li>
                            </ul>
                        </li>
                        <li>Dobór szkieł pryzmatycznych.
                            <ul>
                                <li>Szkła pryzmatyczne są pomocne w leczeniu podwójnego widzenia, zezach porażennych, zezach zbieżnych, rozbieżnych i pionowych o małym kącie oraz w resztkowych zezach pooperacyjnych.</li>
                            </ul>
                        </li>
                        <li>Leczenie operacyjne zeza u dzieci i dorosłych.
                            <ul>
                                <li>Operacje zeza u dorosłych wykonywane są z wykorzystaniem szwów regulowanych, stosowanych w profilaktyce podwójnego widzenia, które może wystąpić po operacji zeza w wieku dorosłym.</li>
                            </ul>
                        </li>
                        <li>Komputerowe badanie wady refrakcji.
                            <ul>
                                <li>Jest wykonywane przy użyciu autorefraktometru stacjonarnego oraz ręcznego. Autorefraktometr ręczny (Welch Allyn) jest niezwykle pomocny do określania wady wzroku u najmłodszych dzieci (ok. 1 roku życia) czy z problemami neurologicznymi.</li>
                            </ul>
                        </li>
                        <li>Ocena widzenia metodą uprzywilejowanego spojrzenia (preferential looking PL).
                            <ul>
                                <li>Metoda wykorzystująca paletki Lea Hyvärinen do określania ostrości wzroku u najmłodszych, kilkumiesięcznych dzieci.</li>
                            </ul>
                        </li>
                        <li>Dobór okularów u dzieci i dorosłych.</li>
                        <li>Badanie dna oka u dzieci i dorosłych.
                            <ul>
                                <li>Jest wykonywane za pomocą soczewki Volka oraz wziernika bezpośredniego.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div>
                    <img class="page-information-O-photo" alt="Małgorzata Kowalczyk" src="~@/assets/O2.webp" >
                </div>
            </div>
            <!-- <div class="page-information-O-registration">
                <button class="btn-mydr-pp" data-doctor="" data-speciality="" data-visitkind="" data-evisit="false" data-appname="drw" data-token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmYWNpbGl0eV9pZCI6MTc5MTh9.xKOWbPssUgM2WIxVj-NSSbQ_uRKwm9WJwPmFhXLx9rY"></button>
            </div> -->
        </div>
    </div>
</template>

<script>

    const mydrScript = document.getElementsByTagName('script')[0];
    const js = document.createElement('script');
    js.src = "https://mydr.pl/static/mydr-pp.min.js";
    mydrScript.parentNode.insertBefore(js, mydrScript);

    js.onload = () => {
        const PatientsPlugin = new window.PatientsPlugin;
        PatientsPlugin.init({
            app: 'https://mydr.pl/patients_plugin',
            plugin: 'https://mydr.pl/static',
        });
    };



export default {

}
</script>

<style>

*{
    box-sizing: border-box;
}

.container-information-O{
    width: 80%;
    margin: 0 auto;
}

.header-box-2{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.label-box-2{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-information-O-header{
    margin-top: 25px;
}

.page-information-O-header p{
    font-size: 34px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: 5px;
    margin-bottom: 0px;
}

.page-information-O-border{
    background-color: #e4bc9b;
    height: 8px;
    width: 84px;
    margin-top: 10px;
}

.page-information-O-label{
    height: 225px;
    width: 100%;
    background-color: #e4bc9b;
    margin-top: 36px;
    -webkit-box-shadow: 0px 5px 20px -9px rgba(0,0,0,1);
    -moz-box-shadow: 0px 5px 20px -9px rgba(0,0,0,1);
    box-shadow: 0px 5px 20px -9px rgba(0,0,0,1);
    border-radius: 10px;
}

.page-information-O-label-content{
    display: flex;
    justify-content: space-around;
    text-align: flex-start;
}

.page-information-O-label-info-2{
    display: flex;
    justify-content: space-around;
    text-align: center;

}

.page-information-O-label-info{
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
    text-align: start;
}

.page-information-O-label-info a{
    text-decoration: none;
}

.info-2{
    margin-bottom: 4px;
}

.info-2 span{
    font-size: 22px;
    font-weight: 600;
}

.page-information-O-label-info p span{
    font-size: 20px;
    font-weight: 100;
}

.page-information-O-label-info p{
    font-size: 16px;
    font-weight: 400;
    margin-top: 4px;
    margin-bottom: 0px;
    color: #fff;
}

.phone-2{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 15px;
    position: relative;
    right: 26px;
}

.phone-2 span{
    font-size: 20px;
    position: relative;
    top: 2px;
    right: 5px;
}

.timetable{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    position: relative;
    right: 23px;
    margin-bottom: 10px;

}

.timetable span{
    font-size: 22px;
    position: relative;
    right: 10px;
}

.page-information-O-label-border-between{
    border-right: 3px dashed #fff;
    margin-top: 30px;
    margin-bottom: 30px;
}

.page-information-O-label-border-between-2{
    margin-right: 30px;
}


.page-information-O-box{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

.page-information-O-info{
    margin-top: 87px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.page-information-O-photo{
    height: 320px;
    margin-left: 64px;
    margin-top: 87px;
    border: 2px solid #C7BA9F;
    border-radius: 3px;
    -webkit-box-shadow: 0px 5px 18px -9px rgba(0,0,0,1);
    -moz-box-shadow: 0px 5px 18px -9px rgba(0,0,0,1);
    box-shadow: 0px 5px 18px -9px rgba(0,0,0,1);
}

.page-information-O-info h2{
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.page-information-O-info p{
    font-size: 16px;
    margin-top: 2px;
    margin-bottom: 30px;
}

.page-information-O-info li{
    list-style-type: none;
    margin-bottom: 10px;
    list-style-type: disc;
    font-weight: 600;
}

.page-information-O-info li span{
    color: #000;
}

.page-information-O-info ul{
    margin-top: 10px;
}

.page-information-O-info ul>li>ul>li{
    list-style-type: disc;
    color: #e4bc9b;
}


.page-information-O-registration{
    align-items: center;
    text-align: center;
    width: 100%;
    margin-bottom: 100px;
    margin-top: 60px;
}


@media screen and (max-width: 480px){
    body{
        overflow-x: hidden;
    }
    .header-box-2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .label-box-2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .container-information-O{
        width: 90%;
    }
    .page-information-O-header p{
        font-size: 26px;
    }
    .page-information-O-border{
        height: 6px;
    }
    .page-information-O-label{
        height: 395px;
        width: 90%;
    }
    .page-information-O-label-content{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .page-information-O-label-info-2{
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .page-information-O-label-border-between-2{
        border-right: 3px dashed #fff;
        margin-top: 25px;
        margin-right: 79px;
        margin-left: 79px;
    }
    .page-information-O-label-info{
        align-items: start;
    }
    .info-2 span{
        font-size: 16px;
    }
    .page-information-O-label-info p span{
        font-size: 14px;
    }
    .page-information-O-label-info p{
        font-size: 12px;
    }
    .phone-2{
        font-size: 16px;
        margin-bottom: 10px;
        position: static;
        right: 0px;
    }
    .phone-2 span{
        font-size: 14px;
        position: relative;
        top: 0px;
        right: 0px;
    }
    .timetable{
        font-size: 14px;
        position: static;
        right: 0px;
        margin-bottom: 10px;

    }
    .timetable span{
        font-size: 16px;
        position: relative;
        right: 5px;
    }
    .page-information-O-label-border-between{
        border-top: 3px dashed #fff;
        margin-top: 25px;
        margin-bottom: 0px;
    }
    .page-information-O-info{
        margin-top: 67px;
    }
    .page-information-O-info h2{
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 5px;
    }

    .page-information-O-info p{
        font-size: 12px;
    }
    .page-information-O-info li{
        font-size: 12px;
    }

    .page-information-O-box{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .page-information-O-photo{
        height: 200px;
        margin-left: 0;
        margin-top: 44px;
    }

}

@media screen and (max-width: 383px){
    .info-2 span{
        font-size: 13px;
    }
    .page-information-O-label-info p span{
        font-size: 12px;
    }
    .page-information-O-label-info p{
        font-size: 11px;
    }
    .page-information-O-info h2{
        font-size: 14px;
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .page-information-O-info p{
        font-size: 12px;
    }
    .page-information-O-info li{
        font-size: 12px;
    }
}

@media screen and (min-width: 481px) and (max-width: 700px){
    .header-box-2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .label-box-2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .container-information-O{
        width: 90%;
    }
    .page-information-O-header p{
        font-size: 34px;
    }
    .page-information-O-border{
        height: 8px;
    }
    .page-information-O-label{
        height: 305px;
        width: 90%;
    }
    .page-information-O-label-content{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .page-information-O-label-info-2{
        display: flex;
        justify-content: space-around;
        text-align: center;
    }
    .page-information-O-label-border-between-2{
        border-right: 3px dashed #fff;
        margin-top: 30px;
        margin-bottom: 5px;
        margin-right: 30px;
        margin-left: 30px;
    }
    .page-information-O-label-info{
        align-items: start;
    }
    .info-2 span{
        font-size: 18px;
    }
    .page-information-O-label-info p span{
        font-size: 16px;
    }
    .page-information-O-label-info p{
        font-size: 14px;
    }
    .phone-2{
        font-size: 16px;
        margin-bottom: 10px;
        position: relative;
        right: 0px;
    }
    .phone-2 span{
        font-size: 16px;
        position: relative;
        top: 0px;
        right: 0px;
    }
    .timetable{
        font-size: 16px;
        position: static;
        right: 0px;
        margin-bottom: 10px;
    }
    .timetable span{
        font-size: 18px;
        position: relative;
        right: 5px;
    }
    .page-information-O-label-border-between{
        display: none;
    }
    .page-information-O-info{
        margin-top: 67px;
    }
    .page-information-O-info h2{
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 5px;
    }

    .page-information-O-info p{
        font-size: 14px;
    }
}

@media screen and (min-width: 601px) and (max-width: 700px){
    .header-box-2{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

@media screen and (min-width: 701px) and (max-width: 1150px){
    .header-box-2{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .label-box-2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .container-information-O{
        width: 80%;
    }
    .page-information-O-header p{
        font-size: 34px;
    }
    .page-information-O-border{
        height: 8px;
    }
    .page-information-O-label{
        height: 335px;
        width: 80%;
    }
    .page-information-O-label-content{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .page-information-O-label-info-2{
        display: flex;
        justify-content: space-around;
        text-align: center;
    }
    .page-information-O-label-border-between-2{
        border-right: 3px dashed #fff;
        margin-top: 30px;
        margin-bottom: 5px;
        margin-right: 30px;
        margin-left: 30px;
    }
    .page-information-O-label-info{
        align-items: start;
    }
    .info-2 span{
        font-size: 22px;
    }
    .page-information-O-label-info p span{
        font-size: 20px;
    }
    .page-information-O-label-info p{
        font-size: 16px;
    }
    .phone-2{
        font-size: 18px;
        margin-bottom: 10px;
        position: relative;
        right: 0px;
    }
    .phone-2 span{
        font-size: 20px;
        position: relative;
        top: 0px;
        right: 0px;
    }
    .timetable{
        font-size: 18px;
        position: relative;
        right: 0px;
        margin-bottom: 10px;

    }
    .timetable span{
        font-size: 20px;
        position: relative;
        right: 5px;
    }
    .page-information-O-label-border-between{
        display: none;
    }
    .page-information-O-info{
        margin-top: 67px;
    }
    .page-information-O-info h2{
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 5px;
    }

    .page-information-O-info p{
        font-size: 16px;
    }
}

@media screen and (min-width: 801px) and (max-width: 900px){
    .container-information-O{
        width: 70%;
    }
}

@media screen and (min-width: 901px) and (max-width: 1024px){
    .container-information-O{
        width: 80%;
    }
}

@media screen and (min-width: 400px) and (max-width: 1100px){
    .page-information-O-photo{
        height: 280px;
        margin-left: 0;
        margin-top: 44px;
    }
}

@media screen and (min-width: 481px) and (max-width: 1100px){
    .page-information-O-box{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (min-width: 1151px) and (max-width: 1280px){
    .page-information-O-label-border-between-2{
        margin-right: 30px;
    }
}

@media screen and (min-width: 1281px) and (max-width: 1365px){
    .page-information-O-label-border-between-2{
        margin-right: 50px;
    }
}

@media screen and (min-width: 1366px) and (max-width: 1440px){
    .page-information-O-label-border-between-2{
        margin-right: 60px;
    }
}
@media screen and (min-width: 1441px){
    .page-information-O-label-border-between-2{
        margin-right: 80px;
    }
}

</style>